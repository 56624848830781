//@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=M+PLUS+1p:700|Montserrat:600|Sen:800&display=swap");

//$font: 'M PLUS 1p', sans-serif;
$font: "Montserrat", sans-serif;
//$font: 'Sen', sans-serif;

$blue: #8c8c8c;

.bg-blue {
  background-color: #323a46;
}

.pointer {
  cursor: pointer;
}

.statistics {
  background-color: $blue;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .title {
    color: white;
    font-size: 18px;
  }

  .count {
    font-size: 28px;
    font-weight: 600;
    margin-top: 10px;
    display: block;
    text-align: right;
    color: white;
  }

  .cases {
    display: block;
    width: 100%;
    margin-top: 1rem;
    border-bottom: 4px solid #7fe84a;
  }

  .deaths {
    display: block;
    width: 100%;
    margin-top: 1rem;
    border-bottom: 4px solid #e60000;
  }

  .recovered {
    display: block;
    width: 100%;
    margin-top: 1rem;
    border-bottom: 2px solid green;
  }
}

// .table-container {
//   height: 415px;
//   overflow-y: auto;
// }

// .table {
//   width: 100%;
//   margin-bottom: 1rem;
//   color: white;
//   border-radius: 2px;
//   background-color: $blue;

//   thead {
//     padding: 10px;
//     font-weight: 700;
//   }

//   tr {
//     border-bottom: 1px solid whitesmoke;
//   }

//   td {
//     padding: 0.5rem;
//   }
// }

// .ant-table-container {
//   background-color: $blue;
//   color: white;
// }

// .ant-table.ant-table-small thead > tr > th {
//   background-color: $blue;
//   font-weight: 700;
//   color: white;
// }

// .ant-table-container table > thead > tr > th {
//   background-color: $blue;
//   font-weight: 700;
//   color: white;
// }

// .ant-table-row {
//   &:hover {
//     color: black !important;
//   }
// }

// CountryCard

.country-card {
  padding: 1.5rem;
  background-color: white;
  color: black;
  margin-bottom: 2rem;

  .title {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  .item {
    display: block;
    padding: 1rem;
    border-bottom: 1px solid silver;

    .title {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
    }

    .content {
      float: right;

      .cases {
        color: #52c41a;
        margin-right: 1rem;
      }

      .deaths {
        color: #f5222d;
      }
    }
  }
}

.global-table {
  overflow-x: auto;
  margin: 0px auto;
  padding: 0;
  width: 100%;
  margin-bottom: 125px;

  @include for-phone-only {
    max-width: 599px;
  }

  @include for-tablet-portrait-up {
    max-width: 800px;
  }

  @include for-tablet-landscape-up {
    max-width: 950px;
  }

  @include for-desktop-up {
    max-width: 1250px;
  }
}

.custom-navbar {
  width: 100%;
  height: 70px;
  color: white;
  background-color: #800000;
  padding: 10px 20px;
  font-size: 30px;
  font-family: $font;

  img {
    width: 35px;
    margin-right: 10px;
  }

  @include for-phone-only {
    height: 60px;
    font-size: 1.3rem;
    text-align: center;

    img {
      width: 30px;
    }
  }
}

.custom-footer {
  position: fixed;
  bottom: 90px;
  width: 100%;
  height: 50px;
  color: black;
  padding: 10px 20px;
  font-size: 14px;

  @include for-phone-only {
    bottom: 6rem;
  }
}

.nav-buttons {
  .button {
    border: 1px solid slategrey;
    background-color: white;
    padding: 10px;
    font-size: 14px;
    margin-right: 5px;
  }

  @include for-phone-only {
    width: 100vh;
    margin: 20px auto;
  }
}

.site-page-header-title {
  font-size: 1.5rem;

  @include for-phone-only {
    font-size: 0.9rem;
  }
}

.nav-title {
  text-align: left;
  font-size: 1.25rem;
  font-weight: 600;

  @include for-phone-only {
    text-align: center;
    font-size: 1rem;
  }
}

.nav-buttons {
  text-align: right;

  @include for-phone-only {
    text-align: center;
  }
}

.bold {
  font-weight: 600;
}

.loader {
  width: 100%;
  height: 100vh;

  .content {
    position: relative;
    top: 30%;
    display: block;
    margin: 0px auto;
    padding: 40px;
    text-align: center;

    p{
      font-size: 26px;
      margin-top: 2rem;
    }

    img{
      width: 700;
    }

    .header {
      img {
        width: 70px;
      }

      span {
        font-weight: 600;
        margin-left: 1rem;
        vertical-align: middle;
      }
    }
  }

  @include for-phone-only {
    .content {
      padding: 0px;

      p{
        font-size: 16px;
        margin-top: 1rem;
      }

      img{
        width: 300px;
      }

      .header {
        img {
          width: 25px;
        }

        span {
          font-size: 20px;
          margin-left: 0.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}

/* Antd Fixes */

.ant-table.ant-table-small tbody > tr > td {
  padding: 8px 2px;
}

.ant-typography {
  color: rgba(0, 0, 0, 0.85);
}

.site-back-top-basic {
  color: rgba(64, 64, 64, 0.6);
}

.ant-page-header-heading-title {
  padding: 0;
}

.ant-table {
  background-image: url("/assets/images/watermark.png");
}

.ant-table tbody > tr.ant-table-row:hover > td {
  background: #fff5cc;
}

// .ant-table {
//   font-size: 16px;
// }

// .ant-tag {
//   font-size: 16px !important;
// }

@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 25px;
  }
}

// .ant-btn{
//   @include for-phone-only{
//     padding: 4px 7px;
//   }
// }

// #components-layout-demo-top-side .logo {
//   width: 300px;
//   height: 45px;
//   background: rgba(255, 255, 255, 0.2);
//   margin: 16px 28px 16px 0;
//   float: left;
//   content: 'Coronavirus'
// }

// .site-layout-background {
//   background: #fff;
// }

// .ant-table.ant-table-small tbody > tr > td:first{
//   margin: 0px 70px;
// }

// tr:nth-of-type(odd) {
//   background: #eee;
// }
